(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.referees.controller:RefereesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.referees')
    .controller('RefereesCtrl', RefereesCtrl);

  function RefereesCtrl(townHalls, $filter, AclService, RefereeList, $state) {
    var vm = this;
    vm.ctrlName = 'RefereesCtrl';
    var organisationList = angular.copy(townHalls);
    if(AclService.isAdmin()) organisationList.push({name: $filter('translate')('COUNCIL'), id: undefined});
     vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'ACTIONS',
      actions: [{
        icon: 'add',
        tooltip: 'Afegir',
        execute: add
      }, {
        icon: 'timer',
      
        tooltip: 'Partits arbitrats',
        execute: partits
      }, {
        icon: 'local_atm',
      
        tooltip: 'Veure factures',
        execute: veurefactures
      },{
        icon: 'filter_3',
      
        tooltip: 'Resum trimestral',
        execute: resumtrimestral
      }]
    };
    
     function veurefactures(){
        $state.go('home.invoicesgroup');
    }
    
     function resumtrimestral(){
        $state.go('home.invoicestrimestral');
    }
    
    function add(){
        $state.go('home.referees.newReferee');
    }
    function partits(){
        $state.go('home.invoiceslist');
    }
    vm.data = {
      title: 'REFEREES.LIST',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'surname', name: 'SURNAME', canOrder: true, orderBy: 'surnameOrder', orderDesc: true},
        {id: 'organisation', name: 'RESPONSIBLE_ORGANISATION', canOrder: true}
      ],
      areRowsClickable: true,
      state: {
        slink: 'home.referees.referee', params: [
          {key: 'licenseId', value: 'id'}
        ]
      },
      defaultOrderBy: 'nameOrder',
      filters: [
        {
          label: 'REFEREES.SELECT_TOWNHALL_OR_COUNCIL',
          showField: 'name',
          filterParam: 'organisationId',
          filterField: 'id',
          required: true,
          options: organisationList
        }
      ],
      filterPromise: function (params) {
        var queryObject = {council: false, organisationId: params.organisationId};
        if(angular.isUndefined(params.organisationId) || _.isNull(params.organisationId)){
          queryObject = {council: true, organisationId: null}
        }
        return RefereeList.query(queryObject).$promise;
      },
      filterFunction: function (data) {
        var persons = [];
        angular.forEach(data, function (licence) {
          var newPerson = {
            id: licence.id,
            organisation: _.isNull(licence.organisationName) ?  $filter('translate')('COUNCIL') : licence.organisationName ,
            name: licence.person.name,
            nameOrder: $filter('lowercase')(licence.person.name),
            surname: licence.person.surname,
            surnameOrder: $filter('lowercase')(licence.person.surname)
          };
          persons.push(newPerson);
        });
        return persons;
      }
    };
  }
}());
